// components/SoonPage.scss
@import 'styles';
@import url('https://fonts.googleapis.com/css?family=Anton&display=swap');


$startPosX: 120%;
$startPosY: -800%;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}



.wrapper{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #1f348f;

  svg{
    position: absolute;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      display: none;
    
    }
  }
}

.container{
  height: 200px;
  width: 200px;
  margin: 300px auto;
  position: relative;
  filter: url(#goo);

    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 80vh;
      width: 100%;
      margin: 20px auto 0px;
    }


  .text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: Anton;
    font-size: 200px;
    z-index: 999;
    color: white;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      font-size: 40px;
      top: 10%;
    }
  }


  .drop{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate($startPosX,$startPosY) scaleX(0.5);
    animation: move 6s cubic-bezier(1,.04,.74,.2) infinite;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 8px;
      width: 8px;
      top: 10%;
      left: 49%;
      animation: movesmall 6s cubic-bezier(1,.04,.74,.2) infinite;
    }
  }
}

.title-h2 {
  color: white;
  margin-bottom: 10px;
  font-size: 1.2em;
}


@keyframes move{
  0%{
    transform: translate($startPosX,$startPosY) scaleX(0.2);
    height: 60px;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 12px;
    }
  }
  30%{
    transform: translate($startPosX,-200%);
    height: 30px;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 8px;
    }
  }
  70%{
    transform: translate($startPosX,100%);
    height: 30px;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 8px;
    }
  }
  100%{
    transform: translate($startPosX,800%) scaleX(0.2);
    height: 60px;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 12px;
    }
  }
}

@keyframes movesmall {
  0%{
    transform: translate($startPosX,$startPosY) scaleX(0.2);
    height: 12px;
    
  }
  30%{
    transform: translate($startPosX,-200%);
    height: 8px;
    
  }
  70%{
    transform: translate($startPosX,100%);
    height: 8px;
    
  }
  100%{
    transform: translate($startPosX,800%) scaleX(0.2);
    height: 12px;
    
  }
}



/* Container to center elements both vertically and horizontally */
.soon-container {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}


.container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // min-height: 100vh;
    // @media only screen and (min-width: 300px) and (max-width: 900px){
    //   align-items: normal;
    //   min-height: auto;
    // }
  }
  
  /* Styles for the title */
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-top: 50px;
    
  }
  
  /* Styles for the description */
  .description {
    font-size: 18px;
    color: #555;
    margin: 20px 0;
  }
  
  /* Styles for the "Coming Soon" message */
  .coming-soon {
    font-size: 24px;
    font-weight: bold;
    color: #ff5722;
    margin-top: 20px;
  }
  
  /* Add any other custom styles you want for the page */
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .title {
      font-size: 20px;
      text-align: center;
    }
  
    .description {
      font-size: 16px;
    }
  
    .coming-soon {
      font-size: 20px;
    }
  }

// Define the animation using keyframes
// @keyframes fadeInOut {
//   0%, 100% {
//     opacity: 0;
//     transform: translateY(-10px);
//   }
//   50% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// Apply the animation to the title element
.title {
  animation: fadeInOut 2s infinite;
}


.social-icons {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  // background-color: #fff;
  padding: 10px;
  // border-radius: 20px;
  // box-shadow: 1px 1px #888;
 a {
    margin: 0 10px;
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;

    svg {
      position: relative;
      @media only screen and (min-width: 300px) and (max-width: 900px){
        display: block !important;
      
      }
    }

    &.twitter::hover {
      color: #1da1f2;
    }
  }
}

.social-icons a.twitter:hover {
  color: #1da1f2; /* Twitter */
}

.social-icons a.instagram:hover {
  color: #c13584; /* Instagram */
}

.social-icons a.youtube:hover {
  color: #ff0000; /* YouTube */
}

.social-icons a.tiktok:hover {
  color: #69c9d0; /* TikTok */
}

.latest-episode {
  width: 100%;
  position: absolute;
  bottom: 10%;
  @media only screen and (min-width: 300px) and (max-width: 900px){
    position: absolute;
    bottom: 50%;
    transform: translate(0%,50%);
  
  }

  iframe {
    border: none;
    height: 180px;
    width: 80%;
    @media only screen and (min-width: 300px) and (max-width: 900px){
      height: 352px;
    }
  }
}

.podcast-container {
  width: 80%;
  height: 150px;

  @media only screen and (min-width: 300px) and (max-width: 900px){
    width: 80%;
    height: auto;
  }
}


 